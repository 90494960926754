import React from "react";
import { cx } from "@/utils/all";

type LabelProps = {
  color: string;
  nomargin?: boolean;
  pill?: boolean;
  children: React.ReactNode;
};


const colors = {
  red: { text: "text-red-700", bg: "bg-red-50" },
  green: { text: "text-emerald-700", bg: "bg-emerald-50" },
  blue: { text: "text-blue-600", bg: "bg-blue-50" },
  orange: { text: "text-orange-700", bg: "bg-orange-50" },
  purple: { text: "text-purple-600", bg: "bg-purple-50" },
  pink: { text: "text-pink-600", bg: "bg-pink-50" },
  yellow: { text: "text-yellow-700", bg: "bg-yellow-50" },
  gray: { text: "text-gray-700", bg: "bg-gray-50" },
  black: { text: "text-black", bg: "bg-gray-200" },
  teal: { text: "text-teal-700", bg: "bg-teal-50" },
};

const BlogLabel: React.FC<LabelProps> = ({ color, nomargin = false, pill = false, children }) => {
  const colorStyles = colors[color] || colors.gray;

  if (pill) {
    return (
      <div
        className={cx(
          "inline-flex items-center justify-center font-bold px-2 h-6 text-sm",
          colorStyles.bg,
          colorStyles.text,
          "rounded-full shrink-0",
          nomargin ? "" : "mt-4",
          "dark:bg-gray-800 dark:text-gray-300"
        )}
      >
        {children}
      </div>
    );
  }

  return (
    <span
      className={cx(
        "inline-block text-xs font-medium tracking-wider uppercase ml-4",
        colorStyles.bg,
        colorStyles.text,
        nomargin ? "" : "mt-5"
      )}
    >
      {children}
    </span>
  );
};

export default BlogLabel;
