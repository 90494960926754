import createImageUrlBuilder from "@sanity/image-url";
import { dataset, projectId } from "@/lib/sanity/config";

type ImageSource = {
  asset?: {
    _ref?: any;
    url?: string;
  };
};

type ImageInfo = {
  src: string;
  width: number;
  height: number;
};

type ImageOptions = {
  maxWidth?: number;
  format?: "jpg" | "png" | "webp";
};

const imageBuilder = createImageUrlBuilder({ projectId, dataset });

export const urlForImage = (
  source: ImageSource,
  options?: ImageOptions
): ImageInfo | undefined => {
  if (!source?.asset || (!source.asset._ref && !source.asset.url)) {
    return undefined;
  }

  // Handle cases where a direct URL is provided
  if (source.asset.url) {
    return {
      src: source.asset.url,
      width: options?.maxWidth || 2000, // Default width if none is provided
      height: Math.round((options?.maxWidth || 2000) * 0.66), // Default aspect ratio
    };
  }

  // Safely access _ref and split dimensions
  const dimensions = source.asset._ref?.split("-")[2];
  if (!dimensions) {
    return undefined;
  }

  const [width, height] = dimensions.split("x").map((num) => parseInt(num, 10));
  if (isNaN(width) || isNaN(height)) {
    return undefined;
  }

  const maxWidth = options?.maxWidth || 2000;
  const format = options?.format;

  let builder = imageBuilder
    .image(source)
    .width(Math.min(width, maxWidth))
    .auto("format");

  if (format) {
    builder = builder.format(format);
  }

  const url = builder.url();

  return {
    src: url,
    width,
    height,
  };
};
