import { apiVersion, dataset, projectId, useCdn } from "./config";
import { createClient } from "next-sanity";
import {
  postquery,
  limitquery,
  paginatedquery,
  configQuery,
  singlequery,
  pathquery,
  allauthorsquery,
  authorsquery,
  postsbyauthorquery,
  postsbycatquery,
  catpathquery,
  catquery,
  getAll,
  searchquery,
  bannersQuery,
  photosQuery,
  iconsQuery,
  topicsQuery,
  topicsBySlugQuery,
  pageHeroBySlugQuery
} from "./groq";

export type PhotosData = {
  _id: string;
  title: string;
  imgSrc: any;
  linkHref?: string;
  alt: string;
  category: string;
};

if (!projectId) {
  console.error(
    "The Sanity Project ID is not set. Check your environment variables."
  );
}

/**
 * Checks if it's safe to create a client instance, as `@sanity/client` will throw an error if `projectId` is false
 */
export const client = projectId
  ? createClient({ projectId, dataset, apiVersion, useCdn })
  : null;

export const fetcher = async ([query, params]) => {
  return client ? client.fetch(query, params) : [];
};

(async () => {
  if (client) {
    const data = await client.fetch(getAll);
    if (!data || !data.length) {
      console.error(
        "Sanity returns empty array. Are you sure the dataset is public?"
      );
    }
  }
})();

// Fetching data functions

export async function getAllPosts() {
  if (client) {
    return (await client.fetch(postquery)) || [];
  }
  return [];
}

export async function getSettings() {
  if (client) {
    return (await client.fetch(configQuery)) || [];
  }
  return [];
}

export async function getPostBySlug(slug) {
  if (client) {
    return (await client.fetch(singlequery, { slug })) || {};
  }
  return {};
}

export async function getAllPostsSlugs() {
  if (client) {
    const slugs = (await client.fetch(pathquery)) || [];
    return slugs.map(slug => ({ slug }));
  }
  return [];
}

// Author
export async function getAllAuthorsSlugs() {
  if (client) {
    const slugs = (await client.fetch(authorsquery)) || [];
    return slugs.map(slug => ({ author: slug }));
  }
  return [];
}

export async function getAuthorPostsBySlug(slug) {
  if (client) {
    return (await client.fetch(postsbyauthorquery, { slug })) || {};
  }
  return {};
}

export async function getAllAuthors() {
  if (client) {
    return (await client.fetch(allauthorsquery)) || [];
  }
  return [];
}

// Category

export async function getAllCategories() {
  if (client) {
    const slugs = (await client.fetch(catpathquery)) || [];
    return slugs.map(slug => ({ category: slug }));
  }
  return [];
}

export async function getPostsByCategory(slug) {
  if (client) {
    return (await client.fetch(postsbycatquery, { slug })) || {};
  }
  return {};
}

export async function getTopCategories() {
  if (client) {
    return (await client.fetch(catquery)) || [];
  }
  return [];
}

// Fetch banners
export async function getBanners() {
  if (client) {
    return (await client.fetch(bannersQuery)) || [];
  }
  return [];
}

// Fetch Photos
export async function getPhotos(category) {
  if (client) {
    try {
      const query = photosQuery(category);
      const params = { category };
      return (await client.fetch(query, params)) || [];
    } catch (error) {
      console.error("Error fetching photos:", error);
      return [];
    }
  }
  return [];
}

export async function getPaginatedPosts({ limit, pageIndex = 0 }) {
  try {
    if (client) {
      const posts = await client.fetch(paginatedquery, {
        pageIndex: pageIndex,
        limit: limit,
      });
      return posts || [];
    }
  } catch (error) {
    console.error("Error fetching paginated posts:", error);
    return [];
  }
  return [];
}


// Fetch icons
export async function getIcons() {
  if (client) {
    return (await client.fetch(iconsQuery)) || [];
  }
  return [];
}

// New functions for creating, updating, and uploading data

// create a new document
export async function createDocument(doc) {
  if (client) {
    try {
      const result = await client.create(doc);
      return result;
    } catch (error) {
      console.error("Error creating document:", error);
      throw error;
    }
  }
}

// update an existing document
export async function updateDocument(_id, updates) {
  if (client) {
    try {
      const result = await client.patch(_id).set(updates).commit();
      return result;
    } catch (error) {
      console.error("Error updating document:", error);
      throw error;
    }
  }
}

// Function to upload an image
export async function uploadImage(file) {
  if (client) {
    try {
      const result = await client.assets.upload("image", file);
      return result;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  }
}

// create post
export async function createPost(post) {
  return await createDocument(post);
}

// Fetch Topics
export async function getAllTopics() {
  if (client) {
    return (await client.fetch(topicsQuery)) || [];
  }
  return [];
}

// Fetch a single topic by slug
export async function getTopicBySlug(slug: string) {
  if (client) {
    return (await client.fetch(topicsBySlugQuery, { slug })) || {};
  }
  return {};
}

//get page hero by slug
export const getPageHeroBySlug = async (slug) => {
  if (!client) {
    throw new Error("Sanity client is not initialized. Check your configuration.");
  }

  try {
    const result = await client.fetch(pageHeroBySlugQuery, { slug });
    console.log("Query result (Page Hero):", result);
    return result;
  } catch (error) {
    console.error("Error fetching Page Hero:", error);
    return null;
  }
};


