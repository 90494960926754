import Link from "next/link";
import BlogLabel from "@/components/ui/blog-label";

type CategoryLabelProps = {
  categories: {
    slug: { current: string };
    color: string;
    title: string;
  }[];
  nomargin?: boolean;
  className?: string;
};

export default function CategoryLabel({
  categories,
  nomargin = false,
  className = "",
}: CategoryLabelProps) {
  return (
    <div className={`flex gap-3 ${className}`}>
      {categories?.length &&
        categories.slice(0).map((category, index) => (
          <Link
            href={`/category/${category.slug.current}`}
            key={index}>
            <BlogLabel nomargin={nomargin} color={category.color}>
              {category.title}
            </BlogLabel>
          </Link>
        ))}
    </div>
  );
}
